import Loader from '@components/Loader';
import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from 'react-router-dom';
import moment from "moment";
import { IoMdArrowRoundBack } from 'react-icons/io';
import ENV from "../utils/env"
import { getApiReq } from "../utils/ApiHandler";
import { showToast } from "../redux/action";

const MerchantsData = () => {
  const [merchant, setMerchant] = useState([])
  const [errors, setErrors] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const getMerchant = () => {

    getApiReq(`/merchant/get-merchant-detail`).then((res) => {
      if (res.status) {
        setMerchant(res.data.data)
      } else {
        dispatch(showToast({ type: "error", message: res.error }));
      }
    });
  }
  useEffect(() => {
    getMerchant();
  }, []);
  return (
    <>

      <div className="bg-gray-200 min-h-screen w-full text-[18px] py-8">
        <button onClick={() => history.goBack()} className='absolute top-5 left-32'> <IoMdArrowRoundBack className='text-[30px]' /></button>

        <form method="post" action="#" >
          <div className="w-full tablet:text-[18px] text-[15px] max-w-[1200px] gap-20 mx-auto flex lg:justify-between tablet:justify-center lg:items-start tablet:items-center lg:flex-row flex-col mt-10">
            <div className="gap-6 tablet:gap-10 tablet:w-[80%] lg:w-[100%] w-full px-4 mx-auto flex flex-col tablet:text-left">
              <div className="border-b-2 border-gray-400 tablet:mx-0 mx-4">
                <p className="font-bold mb-2 tablet:text-left text-center tablet:text-[18px] text-[16px]">
                  Merchant Or Store Entity
                </p>
              </div>
              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="tablet:w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Store or Brand Name:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="border-none w-full tablet:text-[16px] text-[14px]"
                    name="merchant_name"
                    value={merchant[0]?.merchant_name}
                    // onChange={handleChange}
                    type="text"
                    readOnly
                  />
                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Email Address:
                </label>
                <input
                  className="tablet:text-[16px] text-[14px] border-none w-full "
                  type="email"

                  placeholder="Enter email address"
                  name="merchant_email"
                  value={merchant[0]?.merchant_email}
                  readOnly
                />
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Location:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"

                    placeholder="Enter location"
                    name="merchant_location"
                    value={merchant[0]?.merchant_location}
                    readOnly
                  />
                </div>
              </div>

              <div className="flex gap-x-6 tablet:flex-row flex-col tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  POC Name:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full  "
                    type="text"

                    placeholder="Enter POC name"
                    name="merchant_poc_name"
                    value={merchant[0]?.merchant_poc_name}
                    readOnly
                  />

                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-8 leading-7">
                  Contact No:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="merchant_contact"
                    type="number"
                    value={merchant[0]?.merchant_contact}
                    readOnly
                  />

                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-8 leading-7">
                  State:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="state"
                    type="text"
                    value={merchant[0]?.state ? merchant[0]?.state : "U/A"}
                    readOnly
                  />

                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-8 leading-7">
                  City:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="city"
                    type="text"
                    value={merchant[0]?.city ? merchant[0]?.city : "U/A"}
                    readOnly
                  />

                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-8 leading-7">
                  Address:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="address"
                    type="text"
                    value={merchant[0]?.address ? merchant[0]?.address : 'U/A'}
                    readOnly
                  />

                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-8 leading-7">
                  Area Name:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="area_name"
                    type="text"
                    value={merchant[0]?.area_name ? merchant[0]?.area_name : 'U/A'}
                    readOnly
                  />

                </div>
              </div>

              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-8 leading-7">
                  Pin Code:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="pin_code"
                    type="text"
                    value={merchant[0]?.Pin_code ? merchant[0]?.Pin_code : 'U/A'}
                    readOnly
                  />

                </div>
              </div>


              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Merchant URL:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="merchant_url"
                    type="text"
                    value={merchant[0]?.merchant_url}
                    readOnly
                  />

                </div>
              </div>


              <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
                <label className="tablet:w-1/3 tablet:leading-8 leading-7">
                  Select Category:
                </label>
                <div className="flex flex-col">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"
                    value={merchant[0]?.merchant_category}
                    name="merchant_umid"
                    readOnly
                  />
                </div>
              </div>

              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="tablet:w-1/3 tablet:leading-9 leading-7">
                  Select Package:
                </label>
                <div>
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"
                    value={merchant[0]?.merchant_package}
                    name="merchant_umid"
                    readOnly
                  />
                  {/* <p className='m-0 p-0'>{errors ? <span style={{ color: 'red' }}>{errors.merchant_package}</span> : ''}</p> */}
                </div>
              </div>

              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="tablet:w-1/3 tablet:leading-9 leading-7">
                  Business Type:
                </label>
                <div>
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"
                    value={(merchant[0]?.business_type === null) ? 'U/A' : merchant[0]?.business_type}
                    name="merchant_umid"
                    readOnly
                  />
                  {/* <p className='m-0 p-0'>{errors ? <span style={{ color: 'red' }}>{errors.merchant_package}</span> : ''}</p> */}
                </div>
              </div>

            </div>

            <div className="gap-6 tablet:gap-10 tablet:w-[80%] lg:w-[100%] w-full px-4 mx-auto flex flex-col tablet:text-left">
              <div className="border-b-2 border-gray-400  tablet:mx-0 mx-4">
                <p className="font-bold mb-2 tablet:text-left text-center tablet:text-[18px] text-[16px]">
                  Enter Legal Entity Details
                </p>
              </div>
              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  PAN Number:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"

                    placeholder="EOBPP1424L"
                    value={merchant[0]?.merchant_pan}
                    name="merchant_pan"
                    readOnly
                  />
                </div>
              </div>
              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  GST Number:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"

                    placeholder="18AABCU8603R1ZM"
                    value={merchant[0]?.merchant_gst}
                    name="merchant_gst"
                    readOnly
                  />
                </div>
              </div>

              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  UPI ID:
                </label>
                <div>
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    name="merchant_upi"
                    type="text"
                    value={merchant[0]?.merchant_upi}
                    placeholder="Enter UPI id"
                    readOnly
                  />
                </div>
              </div>

              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Select UMID:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"
                    value={merchant[0]?.merchant_umid}
                    name="merchant_umid"
                    readOnly
                  />
                </div>
              </div>

              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Bank Name:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"
                    value={merchant[0]?.bank_name === null ? 'U/A' : merchant[0]?.bank_name}
                    name="bank_name"
                    readOnly
                  />
                </div>
              </div>

              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  IFSC Code:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"
                    value={(merchant[0]?.ifsc_code === null) ? 'U/A' : merchant[0]?.ifsc_code}
                    name="merchant_umid"
                    readOnly
                  />
                </div>
              </div>

              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Account Holder Name:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"
                    value={(merchant[0]?.account_holder_name === null) ? 'U/A' : merchant[0]?.account_holder_name}
                    name="merchant_umid"
                    readOnly
                  />
                </div>
              </div>

              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
                  Account Number:
                </label>
                <div className="flex flex-col w-full">
                  <input
                    className="tablet:text-[16px] text-[14px] border-none w-full"
                    type="text"
                    value={(merchant[0]?.account_number === null) ? 'U/A' : merchant[0]?.account_number}
                    name="merchant_umid"
                    readOnly
                  />
                </div>
              </div>

              <div className="flex tablet:flex-row flex-col gap-x-6  tablet:mx-0 mx-4">
                <label className="tablet:w-1/3 tablet:leading-9 leading-7">
                  Logo:
                </label>
                <div className='w-[200px]'>
                  {/* <a href={`${ENV.BASE_URL}/upload/brand_logo/${image}`} target="_blank">{image}</a> */}
                  {/* https://api.inburg.com/merchant_logo/1672050192013.jpg */}
                  <img src={`${ENV.BASE_URL}/merchant_logo/${merchant[0]?.merchant_logo}`} alt='Logo' className='w-full h-full' />
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default MerchantsData