import React from 'react'

const Footer = () => {
  return (
    <div className="flex items-center bg-gray-700">
      <div className="container py-4">
        <ul className=" grid grid-cols-3">
          <li className='flex items-center justify-center gap-2 text-white'>
            <p>Contact :</p>
            <p>support@inburg.com  </p>
          </li>
          <li className='flex items-center justify-center gap-2 text-white'>
            <p>Address :</p>
            <p> Inburg India Pvt Ltd </p>
          </li>

          <li className='flex items-center justify-center gap-2 text-white'>
            <p>Reach us at:</p>
            <p> 9971217965, 8692909978</p>
          </li>

        </ul>
        <p className='flex items-center justify-center text-white text-[14px]'>203, 2-A/3, S/F Front Side, Asaf Ali Road Old Turkman Gate Delhi Delhi North East DL 110002 - Below Inburg India Pvt Ltd</p>
      </div>
    </div >
  )
}

export default Footer