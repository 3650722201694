import Navbar from '@components/layout/Navbar';
import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from 'react-router-dom';
import moment from "moment";
import { getApiReq } from "../utils/ApiHandler";
import { showToast } from "../redux/action";
import { StartEndDateValidation } from "../utils/auth";
import { isYupError, parseYupError } from "../utils/Yup";

const MerchantList = () => {
  const [getAllCoupons, setGetAllCoupons] = useState();
  const [errors, setErrors] = useState();
  const [date, setDate] = useState({
    Start: '',
    End: ''
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const getCoupon = () => {
    getApiReq(`/merchant/get-merchant-data?startDate=${date.Start}&endDate=${date.End}`).then((res) => {
      if (res.status) {
        setGetAllCoupons(res.data.data);
      } else {
        dispatch(showToast({ type: "error", message: res.error }));
      }
    });
  }

  const handleChange = (name, e) => {
    setDate({ ...date, [name]: moment(new Date(e.target.value)).format("YYYY-MM-DD hh:mm:ss a") })
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const getCouponData = async () => {
    try {
      setErrors({});
      await StartEndDateValidation.validate(date, {
        abortEarly: false,
      });
      getCoupon()

    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.log("error");
      }
    }
  }

  useEffect(() => {
    getCoupon()
  }, []);

  return (
    <>
      <Navbar />

      <div className="flex tablet:flex-row flex-col tablet:p-4 p-3 justify-between bg-[#CCCCCC]">
        <div className="flex gap-5 h-[60px]">
          <div>

            <input className="tablet:ml-2 h-[40px] focus:border-gray-300 focus:ring-gray-400" name="Start" type="datetime-local"
              onChange={(e) => handleChange("Start", e)} />
            <p className="tablet:text-[16px] text-[13px] pl-3">
              {errors ? (
                <span style={{ color: "red" }}>
                  {errors.Start}
                </span>
              ) : (
                ""
              )}
            </p>
          </div>

          <div>
            <input className="tablet:ml-2 h-[40px] focus:border-gray-300 focus:ring-gray-400" name="End" type="datetime-local"
              onChange={(e) => handleChange("End", e)} />
            <p className="tablet:text-[16px] text-[13px] pl-3">
              {errors ? (
                <span style={{ color: "red" }}>
                  {errors.End}
                </span>
              ) : (
                ""
              )}
            </p>
          </div>

          <button onClick={getCouponData} className="bg-gray-600 text-white font-semibold h-[40px]">Search</button>
        </div>
      </div>

      <div className="overflow-x-auto lg:overflow-x-hidden ">
        <table className="tablet:w-[200%] lg:w-[100%] ">
          <thead className="">
            <tr className="bg-gray-600 text-white tablet:text-[20px] text-[16px]">
              <th className="w-1/10 border-l-2 border-b-2 p-3">Merchants Name</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Coupons Count</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Coupons Issued</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Coupons Redeemed</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Coupons Remaining</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Total Sales Value<span>(&#8377;)</span>
              </th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Total Commission<span> (&#8377;)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {getAllCoupons?.map((item) => (
              <tr className="tablet:text-[17px] text-[13px]">
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  <button className="text-sky-700 capitalize text-[18px] underline hover:text-sky-900 underline-offset-2 " onClick={() => history.push(`/MerchantCoupon`)}>{item.merchant_name}</button>
                </td>
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {(item.offer_coupon_count) ? item.offer_coupon_count : 0}
                </td>
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {(item.total_coupon) ? item.total_coupon : 0}
                </td>
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {(item.redeemed_coupon) ? item.redeemed_coupon : 0}
                </td>
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {(item.remaning_coupon) ? item.remaning_coupon : 0}
                </td>
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {(item.total_sale) ? item.total_sale : 0}
                </td>
                <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                  {(item.total_commission) ? item.total_commission : 0}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}


export default MerchantList
