import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../redux/action";
import { useHistory, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { PasswordValidation } from "../utils/auth";
import { postReq } from "../utils/ApiHandler";
import { isYupError } from "../utils/Yup";
import { parseYupError } from "../utils/Yup";
import { isUserLoggedIn } from '@utils/basicReq'

const ChangePassword = () => {
  const [errors, setErrors] = useState(true);
  const [hidePass, sethidePass] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false)
  // const navigate = useNavigate();
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, setUser] = useState({});

  const { token } = useParams();

  const [form, setForm] = useState({
    password: '',
    confirmPassword: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    })
    setErrors({
      ...errors,
      [name]: ''
    })
    setCheckPassword(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      await PasswordValidation.validate(form, {
        abortEarly: false,
      });
      if (form.password === form.confirmPassword) {
        postReq(`/change-password`, { "password": form.password, "token": token }).then((res) => {
          if (res.status) {
            dispatch(showToast({ type: 'success', message: 'Password change successfully.' }))
            history.push('/')
          }
        }).catch((e) => {
          console.log('error', e);
        })
      }
      else {
        setCheckPassword(true)
        dispatch(showToast({ type: 'error', message: 'Please enter both password are same.' }))
      }
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.log('error');
      }
    }
  }

  useEffect(() => {
    if (isUserLoggedIn()) {
      // history.push('/MerchantList')
    }
  })

  return (
    <>
      <div className=" flex justify-center items-center h-screen">
        <form action="#" method="post" onSubmit={handleSubmit}>
          <div className="w-[350px] tablet:w-[700px] border-2 border-gray-100 tablet:p-[45px] px-6 py-6">
            <div className="w-full h-full bg-gray-200 border-2 border-gray-200 flex justify-center items-center">
              <div className="tablet:space-y-[25px] tablet:w-[75%] w-full md:py-12 md:p-0 p-6">
                <div className='w-[170px] m-auto'>
                  <img src='/images/Logoblack.png' alt='' />
                </div>
                <div>
                  <h1 className="md:text-[25px] text-[20px] text-center ">
                    Reset Password
                  </h1>
                </div>
                <div className="flex flex-col md:space-y-[40px] space-y-4 text-end">
                  <div className="tablet:pt-2 pt-4 flex justify-between text-[20px] tablet:items-center text-start tablet:flex-row flex-col">
                    <label className="md:text-[20px] text-[15px]">Password</label>
                    <div className=''>
                      <input
                        className="focus:border-gray-400 focus:ring-gray-500 border-gray-300 tablet:text-[16px]
                       text-[14px] w-full"
                        type="password"
                        name="password"
                        // value={MerchantLogin.email}
                        onChange={handleChange}
                        placeholder="Enter Password"
                        error={errors?.password}
                      ></input>
                      <p className="tablet:text-[16px] text-[13px]">
                        {errors ? (
                          <span style={{ color: "red" }}>{errors.password}</span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="tablet:pt-2 pt-4 flex justify-between text-[20px] tablet:items-center text-start tablet:flex-row flex-col">
                    <label className="md:text-[20px] text-[15px]">Confirm Password</label>
                    <div>
                      <input
                        className="focus:border-gray-400 focus:ring-gray-500 border-gray-300 tablet:text-[16px]
                       text-[14px] w-full flex-shrink-0"
                        type="password"
                        name="confirmPassword"
                        // value={MerchantLogin.email}
                        onChange={handleChange}
                        placeholder="Enter Password"
                        error={errors?.confirmPassword}
                      ></input>
                      <p className="tablet:text-[16px] text-[13px]">
                        {errors ? (
                          <span style={{ color: "red" }}>{errors.confirmPassword}</span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button className="bg-blue-600 text-white tablet:mt-[15px] mt-4 md:px-[20px] md:py-[6px] px-2 py-1 tablet:text-[16px] text-[15px]">
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default ChangePassword