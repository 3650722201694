import Navbar from '@components/layout/Navbar';
import React, { useEffect, useState } from 'react';

import { useDispatch } from "react-redux";
import { NavLink, useHistory } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import moment from "moment";
import { getApiReq } from "../../utils/ApiHandler";
import { showToast } from "../../redux/action";
import { StartEndDateValidation } from "../../utils/auth";
import { isYupError, parseYupError } from "../../utils/Yup";

const MerchantCoupon = () => {
  const [allCouponsData, setAllCouponsData] = useState([])
  const [redeemedStatus, setRedeemedStatus] = useState('')
  const [errors, setErrors] = useState();
  const [date, setDate] = useState({
    Start: '',
    End: ''
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const getAllCoupons = () => {

    getApiReq(`/merchant/get-coupon-data?status=${redeemedStatus}&startDate=${date.Start}&endDate=${date.End}`).then((res) => {
      if (res.status) {
        setAllCouponsData(res.data.data)
      } else {
        dispatch(showToast({ type: "error", message: res.error }));
      }
    });
  }
  useEffect(() => {
    getAllCoupons();
  }, [redeemedStatus]);

  const redeemedCoupon = () => {
    if (redeemedStatus === '') {
      setRedeemedStatus(1);
    } else {
      setRedeemedStatus('');
    }
  }

  const handleChange = (name, e) => {
    setDate({ ...date, [name]: moment(new Date(e.target.value)).format("YYYY-MM-DD hh:mm:ss a") })
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const getCouponData = async () => {
    try {
      setErrors({});
      await StartEndDateValidation.validate(date, {
        abortEarly: false,
      });
      getAllCoupons()

    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.log("error");
      }
    }
  }
  return (
    <>
      <Navbar />

      <div className="flex tablet:flex-row flex-col tablet:p-4 p-3 justify-between bg-[#CCCCCC]">

        <div className='flex items-center'>
          <button onClick={() => history.goBack()} className=''> <IoMdArrowRoundBack className='text-[25px]' /></button>
          <div class="flex gap-5 h-[60px]">
            <div>
              <input class="tablet:ml-2 h-[40px] focus:border-gray-300 focus:ring-gray-400" name="Start" type="datetime-local"
                onChange={(e) => handleChange("Start", e)} />
              <p className="tablet:text-[16px] text-[13px] pl-3">
                {errors ? (
                  <span style={{ color: "red" }}>
                    {errors.Start}
                  </span>
                ) : (
                  ""
                )}
              </p>
            </div>

            <div>

              <input class="tablet:ml-2 h-[40px] focus:border-gray-300 focus:ring-gray-400" name="End" type="datetime-local"
                onChange={(e) => handleChange("End", e)} />
              <p className="tablet:text-[16px] text-[13px] pl-3">
                {errors ? (
                  <span style={{ color: "red" }}>
                    {errors.End}
                  </span>
                ) : (
                  ""
                )}
              </p>
            </div>

            <button onClick={getCouponData} className="bg-gray-600 text-white font-semibold h-[40px]">Search</button>
          </div>
        </div>
        {/* <div>
          <select className="focus:border-gray-300 focus:ring-gray-400">
            <option>Merchants name</option>
            <option>A</option>
            <option>B</option>
            <option>C</option>
            <option>D</option>
          </select>
        </div>
        <div className="tablet:mr-10">
          <input
            className="w-[220px] focus:border-gray-300 focus:ring-gray-400"
            type="text"
            name="daterange"
            value=""
          />
        </div> */}

        <div className="flex items-center gap-4 tablet:mr-4 tablet:mt-0 mt-3 justify-end">

          <label
            for="default-toggle"
            class="inline-flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              value={redeemedStatus}
              id="default-toggle"
              class="sr-only peer"
              onChange={redeemedCoupon}
            />
            <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
            <span class="ml-3 tablet:text-[18px] text-[15px]">
              Redeemed only
            </span>
          </label>
        </div>
      </div>

      <div className="">
        <table className="tablet:w-[200%] lg:w-[100%]">
          <thead className="sticky top-0">
            <tr className="bg-gray-600 text-white tablet:text-[20px] text-[16px]">
              <th className="w-1/10 border-l-2 border-b-2 p-3">Coupon Code</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Redeemed</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Issued</th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Coupon Value<span></span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Net Sales Value<span>(&#8377;)</span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Total Value<span>(&#8377;)</span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Merchant Payout <span> (&#8377;)</span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Inburg Commission<span> (&#8377;)</span></th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">Payment Status</th>
            </tr>
          </thead>
          <tbody>
            {(allCouponsData?.length > 0) ?
              allCouponsData?.map((item, index) => {
                return (
                  <tr className="tablet:text-[17px] text-[13px]">
                    <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{item.coupon_name}</td>
                    <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                      {(item.redeemed_at === null) ? '--' : moment(new Date(item.redeemed_at)).format("DD-MM-YYYY hh:mm:ss a")}
                    </td>
                    <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                      {moment(new Date(item.coupon_created_at)).format("DD-MM-YYYY hh:mm:ss a")}
                    </td>
                    <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                      {`${item.offer_discount} ${(item.offer_type === 'FLAT') ? item.offer_type : '%'} `}
                    </td>
                    <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                      {(item.net_amount) ? item.net_amount : 0}
                    </td>
                    <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                      {(item.total_amount) ? item.total_amount : 0}
                    </td>
                    <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                      {(item.merchant_amount) ? item.merchant_amount : 0}
                    </td>
                    <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                      {(item.total_commission) ? item.total_commission : 0}
                    </td>
                    <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">
                      {(item.payment_status) ? item.payment_status : 'U/A'}
                    </td>
                  </tr>
                )
              }) :
              <tr className="tablet:text-[18px] text-[14px]">
                <td colSpan={8} className=" text-center border-b-2 border-gray-300 py-2">Data Not Found</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default MerchantCoupon
