import React from 'react'
import { NavLink } from 'react-router-dom'
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

const Navbar = () => {
  const history = useHistory();
  const handleLogout = () => {
    Cookies.remove('user');
    history.push('/')
  }
  return (
    <div className="flex justify-between bg-gray-700 items-center py-2">
      <div className="tablet:ml-4 ml-2">
        <NavLink to={"/MerchantList"}>
          <img className="tablet:w-[170px] w-[90px]" src="/images/Logo.png" />
        </NavLink>
      </div>

      <div className="flex tablet:space-x-8 space-x-4 bg-gray-700 text-white  tablet:mr-6 mr-4">

        <NavLink to={"/MerchantCoupon"}>
          <button className='tablet:text-[25px] text-[16px]'>Coupons</button>
        </NavLink>

        <NavLink to={"/MerchantsData"}>
          <button className='tablet:text-[25px] text-[16px]'>Merchant Details</button>
        </NavLink>

        <NavLink to={"/PaymentData"}>
          <button className='tablet:text-[25px] text-[16px]'>Payments</button>
        </NavLink>

        <NavLink to={"/"}>
          <div className='tablet:text-[25px] text-[16px]' onClick={handleLogout}>Logout</div>
        </NavLink>
      </div>
    </div>
  )
}

export default Navbar