import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { showToast } from "../redux/action";
import { useHistory } from "react-router-dom";
import { isUserLoggedIn } from '@utils/basicReq'

import { ForgotValidation } from "../utils/auth";
import { postReq } from "../utils/ApiHandler";
import { isYupError } from "../utils/Yup";
import { parseYupError } from "../utils/Yup";
import Loader from "../components/Loader";


const ForgotPassword = () => {
  const [errors, setErrors] = useState(true);
  const [hidePass, sethidePass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const navigate = useNavigate();
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, setUser] = useState({});

  const [merchantLogin, setMerchantLogin] = useState({
    email: "",
  });

  const handleInput = (event) => {
    const { name, value } = event.target;
    setMerchantLogin({ ...merchantLogin, [name]: value });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleForm = async (event) => {
    event.preventDefault();
    try {
      setErrors({});
      await ForgotValidation.validate(merchantLogin, {
        abortEarly: false,
      });
      setIsLoading(true);
      postReq(`/forgot-password`, merchantLogin)
        .then((res) => {
          if (res.status) {
            if (res.data.data) {
              setIsLoading(false);
              dispatch(
                showToast({ type: "success", message: "Please Check your email" })
              );
            } else {
              setIsLoading(false);
              dispatch(showToast({ type: "error", message: res.data.error }));
            }
          } else {
            setIsLoading(false);
            dispatch(showToast({ type: "error", message: res.error }));
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.log("error");
      }
    }
  };

  useEffect(() => {
    if (isUserLoggedIn()) {
      // history.push('/MerchantList')
    }
  })

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className=" flex justify-center items-center h-screen">
        <form action="#" method="post" onSubmit={handleForm}>
          <div className="w-[350px] tablet:w-[700px] border-2 border-gray-100 tablet:p-[45px] px-6 py-6">
            <div className="w-full h-full bg-gray-200 border-2 border-gray-200 flex justify-center items-center">
              <div className="tablet:space-y-[25px] tablet:w-[75%] w-full md:py-12 md:p-0 p-6">
                <div className='w-[170px] m-auto'>
                  <img src="images/Logoblack.png" alt='' />
                </div>
                <div>
                  <h1 className="md:text-[25px] text-[20px] text-center ">
                    Forgot Password
                  </h1>
                </div>
                <div className="flex flex-col md:space-y-[40px] space-y-4 text-end items-center">
                  <div className="tablet:pt-2 pt-4 flex tablet:space-x-[53px] lg:space-x-[61px] text-[20px] tablet:items-center text-start tablet:flex-row flex-col">
                    <label className="md:text-[20px] text-[15px]">Email</label>
                    <div>
                      <input
                        className="focus:border-gray-400 focus:ring-gray-500 border-gray-300 tablet:text-[16px]
                       text-[14px] w-full"
                        type="email"
                        name="email"
                        // value={MerchantLogin.email}
                        onChange={handleInput}
                        placeholder="Enter Email Address"
                        error={errors?.email}
                      ></input>
                      <p className="tablet:text-[16px] text-[13px]">
                        {errors ? (
                          <span style={{ color: "red" }}>{errors.email}</span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="text-center">

                  <button className="bg-blue-600 text-white tablet:mt-[15px] mt-4 md:px-[20px] md:py-[6px] px-2 py-1 tablet:text-[16px] text-[15px]">
                    Reset Password
                  </button>

                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default ForgotPassword