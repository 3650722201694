import * as yup from 'yup';
const name = /^[a-zA-Z]+$/
const number = (/^[0-9\b]+$/)
const gst = /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/

// --------------------LoginValidation------------------------------------------------------------
export const LoginValidation = yup.object().shape({
  email: yup.string().required('Please enter email Address.')
    .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please enter valid email.'),
  password: yup.string().required('Please enter password.')
})


// --------------------ForgotValidation------------------------------------------------------------
export const ForgotValidation = yup.object().shape({
  email: yup.string().required('Please enter email Address.')
    .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please enter valid email.')
})


// --------------------PasswordValidation------------------------------------------------------------
export const PasswordValidation = yup.object().shape({
  password: yup.string().required('Please enter password')
    .matches(/^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/, `Enter a password according to the following rules:\n• Password length should not be less than 8 characters,\n• Password must contain at least one of the following group symbols:\n\t1. Capital Letters: {A..Z}\n\t2. Small Letters: {a..z}\n\t3. Numeric characters: 0123456789\n\t4. Special characters:  ~!@#$%^&*()-_=+ []{}|;: ,.<>/?`),
  confirmPassword: yup.string().required('Please enter confirm password')
    .matches(/^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/, `Enter a password according to the following rules:\n• Password length should not be less than 8 characters,\n• Password must contain at least one of the following group symbols:\n\t1. Capital Letters: {A..Z}\n\t2. Small Letters: {a..z}\n\t3. Numeric characters: 0123456789\n\t4. Special characters:  ~!@#$%^&*()-_=+ []{}|;: ,.<>/?`)
})


// --------------------BrandValidation -------------------------------------------------------------
export const StartEndDateValidation = yup.object().shape({
  Start: yup.string().required('Please enter start date'),
  End: yup.string().required('Please enter end date'),
})