
import React from 'react'
import { HIDE_TOAST } from "@redux/action/actionConstants";
import { showToast } from "@redux/action"
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "@pages/NotFound/NotFound";
// import Home from "@pages/Home/Home";
import ProtectedRoutes from "./ProtectedRoutes";
import Profile from "@pages/profile";
import UserRoutes from './UserRoutes';
import './index.css';
// import { Button, useToast } from "@chakra-ui/react";
import CustomSnackBar from '@components/CustomSnackBar';
import MerchantCoupon from '@pages/MerchantCoupon/MerchantCoupon';
import MerchantLogin from '@pages/MerchantLogin';
import MerchantList from '@pages/MerchantList';
import MerchantsData from '@pages/MerchantsData';
import PaymentData from '@pages/PaymentData';
import ForgotPassword from '@pages/ForgotPassword';
import ChangePassword from '@pages/ChangePassword';
import Footer from '@components/layout/Footer';


export const AuthContext = React.createContext({});

const Routes = ({ }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { type, message, isVisible } = useSelector(
    (state) => state.toastReducer
  );

  return (
    <>
      <CustomSnackBar
        open={isVisible}
        autoHideDuration={5000}
        severity={type}
        message={message}
      >

      </CustomSnackBar>

      <div className='min-h-screen flex flex-col w-full'>
        <div className='flex-1 w-full'>
          <Switch>

            <Route path={'/'} exact component={MerchantLogin} />
            <Route path={'/ForgotPassword'} exact component={ForgotPassword} />
            <Route path={'/change-password/:token'} exact component={ChangePassword} />
            <ProtectedRoutes path={'/MerchantCoupon'} exact component={MerchantCoupon} />
            <ProtectedRoutes path={'/MerchantList'} exact component={MerchantList} />
            <ProtectedRoutes path={'/MerchantsData'} exact component={MerchantsData} />
            <ProtectedRoutes path={'/PaymentData'} exact component={PaymentData} />

            {/* <Route path={"/user"} render={(props) => <UserRoutes {...props} />}
        /> */}
            {/* <ProtectedRoutes path={'/profile'} exact component={Profile} /> */}
            <Route path={'/*'} exact component={NotFound} />

          </Switch>
        </div>
        {pathname === '/' || pathname === '/MerchantsData' || pathname === '/ForgotPassword' || pathname.includes('/change-password') ? null : <Footer />}
      </div>
    </>
  );
}

const App = () => {
  const [user, setUser] = React.useState({})
  return (
    <Router>
      <AuthContext.Provider value={{ user, setUser }}>
        <Routes />
      </AuthContext.Provider>
    </Router>
  )
}
export default App;
